<template>
  <div class="mobile-files">
    <FilePreview
      v-for="(file, fileIndex) in props.content.files"
      :key="fileIndex"
      class="mt-6"
      :file="file"
      :hub="props.hub"
      :content="props.content"
    />
  </div>

  <div class="files">
    <div class="w-1/2">
      <div v-for="(file, fileIndex) in props.content.files" :key="fileIndex">
        <FilePreview
          v-if="fileIndex % 2 === 0"
          class="mt-6"
          :file="file"
          :hub="props.hub"
          :content="props.content"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div v-for="(file, fileIndex) in props.content.files" :key="fileIndex">
        <FilePreview
          v-if="fileIndex % 2 !== 0"
          class="mt-6"
          :file="file"
          :hub="props.hub"
          :content="props.content"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FilePreview } from './file-preview';
import { ContentDto } from '~/api/contents';
import { HubDto } from '~/api/hubs';

type Props = {
  hub: HubDto;
  content: ContentDto;
};
const props = defineProps<Props>();
</script>

<style scoped>
.files {
  @apply hidden;
}

@media only screen and (min-width: 700px) {
  .files {
    @apply flex gap-6;
  }

  .mobile-files {
    @apply hidden;
  }
}
</style>
